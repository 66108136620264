/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
Note: any .css or .scss files included in the 'styles' directory
will be correctly compiled during `gulp serve` and `gulp`
 */
html {
  font-size: 62.5%;
  text-size-adjust: 100%;
}
body {
  color: #000;
  font-family: Helvetica Neue, Helvetica, "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, "メイリオ", Meiryo, sans-seri;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 0.02em;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
img {
  width: 100%;
  max-width: 100%;
}
.cc-button {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2;
  height: auto;
  padding: 0.25em 2em;
  background: #cd420f;
  border-radius: 2px;
}
.cc-button:hover {
  background: #cd420fcc;
}
@media screen and (max-width: 479px) {
  .cc-button {
    font-size: 1.0rem;
  }
}

/*  =======================
header
======================= */
.cc-layout__header-row {
  background-color: #fff;
  justify-content: space-between;
  padding: 0 40px;
}
.cc-layout-title {
  width: 100%;
  max-width: 180px;
  margin: 0;
}
@media screen and (max-width: 479px) {
  .cc-layout__header-row {
    padding: 0 15px;
  }
  .cc-layout-title {
    max-width: 120px;
  }
  .cc-button {
    font-size: 1.2rem;
  }
}

/*  =======================
main
======================= */

/*  -----------------
signature
----------------- */
.cc-signature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background: #4c4948;
}
.cc-signature__text {
  font-size: 1.0rem;
  color: #fff;
  margin: 0 0 10px;
}
.cc-signature__company {
  width: 200px;
  margin: 0 0 0 1em;
}
.cc-signature__company-label {
  font-size: 1.0rem;
  color: #fff;
}
.cc-signature__company-detail {
  margin: 0;
}
@media screen and (max-width: 479px) {
  .cc-signature {
    display: block;
    padding: 5px 15px 15px;
  }
  .cc-signature__parts {
    margin-top: 10px;
  }
  .cc-signature__company {
    width: 190px;
    margin: 0;
  }
}

/*  -----------------
top
----------------- */
.cc-top {
  height: calc(100vh - 64px - 41px);
  min-height: 662px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .cc-top {
    height: calc(100vh - 56px - 41px);
  }
}
@media screen and (max-width: 659px) {
  .cc-top {
    min-height: inherit;
  }
}
@media screen and (max-width: 479px) {
  .cc-top {
    padding: 40px 15px;
  }
}
.cc-top__contents {
  position: relative;
}
.cc-top__contents-image {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
}
.cc-top__contents-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  margin: 10px 0 0;
}
.cc-top__contents-text--main {
  display: block;
  font-size: 4.0rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}
.cc-top__contents-text--sub {
  display: block;
  text-align: center;
  margin-top: 10px;
}
@media screen and (max-width: 839px) {
  .cc-top__contents-text--main {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 479px) {
  .cc-top__contents-text--main {
    font-size: 1.8rem;
  }
  .cc-top__contents-text--sub {
    font-size: 1.2rem;
  }
}

/*  -----------------
contact
----------------- */
.cc-contact {
  height: calc(100vh - 64px - 41px);
}
.cc-contact iframe {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .cc-contact {
    height: calc(100vh - 56px - 41px);
  }
}

/*  =======================
footer
======================= */
.cc-footer {
  padding: 15px 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cc-footer__logo {
  width: 100px;
  margin-right: 15px;
}
.cc-footer__copy {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cc-footer__copy-text {
  font-size: 1.0rem;
}
@media screen and (max-width: 479px) {
  .cc-footer {
    padding: 15px;
  }
}


